import { storeToRefs } from 'pinia'
import { useUserApi } from '~/api/user'
import { useAppStore } from '~/store/app'
import { REFRESH_TOAST } from '~~/constants/toast'
import { useAccountStore } from '~/store/account'
import useModal from '~/composables/useModal'
interface AxiosRequestConfig {
  progress: boolean
  bypassErrorToast: boolean
}
export const useRefresh = () => {
  const { REFRESH } = useUserApi()
  const { $pinia, $device } = useNuxtApp()
  const store = useAppStore($pinia)
  const { currentUser, refreshInterval } = storeToRefs(store)
  const { setCurrentUser, setRefreshInterval } = store
  const { $axios, $alertMsg } = useNuxtApp()
  const { closeAllModal } = useModal()
  const { updateTransactionDetail, updateListUserBank } = useAccountStore()
  const refreshToken = async (isCheckCookie?: boolean) => {
    try {
      if (currentUser.value !== null || isCheckCookie) {
        const { data: response } = await $axios.get(REFRESH, {
          progress: false,
          bypassErrorToast: currentUser.value === null
        } as AxiosRequestConfig)
        if (response?.status === 'OK' || response?.status_code === 'SUCCESS') {
          setCurrentUser(response.user)
          return response.user
        } else {
          logoutOtherDevices()
        }
      }
    } catch (error: any) {
      const code = parseInt(error.response && error.response.status)
      if (code === 401) {
        logoutOtherDevices()
      }
    }
  }

  const refreshTokenInterval = () => {
    try {
      const intervalTime = useRuntimeConfig().REFRESH_INTERVAL
      const interval: any = setInterval(async () => {
        await refreshToken(false)
      }, intervalTime)
      setRefreshInterval(interval)
    } catch (error) {
      console.log('refreshTokenInterval', error)
    }
  }

  const logoutOtherDevices = () => {
    if (refreshInterval.value !== null) {
      clearInterval(refreshInterval.value)
      setRefreshInterval(null)
    }
    if (currentUser.value) {
      setCurrentUser(null)
      updateTransactionDetail(null)
      closeAllModal()
      updateListUserBank([])
      $alertMsg('warning', REFRESH_TOAST.ERROR)
      useRouter().push({ path: '/' })
    }
    localStorage.clear()
  }

  return {
    refreshToken,
    refreshTokenInterval
  }
}
